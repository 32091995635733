/** @jsx jsx */
import PropTypes from 'prop-types'
import { Heading, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const Author = ({ name }) => {
  const translate = useTranslate()

  return (
    <Heading
      as="h3"
      variant="h3"
      sx={{
        width: '100%',
        padding: ['1rem 0'],
        textAlign: ['center', null, 'left']
      }}
    >
      {translate('articles.author_by')} {name}
    </Heading>
  )
}

Author.propTypes = {
  name: PropTypes.string
}

export default Author
